
import AppContentLayout from '@bd/admin/components/AppContentLayout.vue'
import {
  ConfirmDialog,
  EventDetails,
  useCalendarEventActions,
  useLoadableResourceDataState,
} from '@bd/components'
import { defineComponent } from 'vue'

export default defineComponent({
  components: { AppContentLayout, EventDetails, ConfirmDialog },
  setup() {
    const { eventDetails, ...calendarEventActions } = useCalendarEventActions()
    const eventDetailsDataState = useLoadableResourceDataState(eventDetails)

    return {
      eventDetails,
      ...eventDetailsDataState,
      ...calendarEventActions,
    }
  },
})
