<template>
  <AppContentLayout backAction>
    <template #back-action>
      <Button
        @click="$router.push({ name: 'Calendar' })"
        icon="pi pi-chevron-left"
        class="p-button-secondary p-button-circle"
      />
    </template>
    <EventDetails
      v-if="isPresent && !isLoading"
      :event="eventDetailsContent"
      :showEditIcon="true"
      @eventEdit="onEventEdit"
      @eventAccept="onEventAccept"
      @eventReject="onEventReject"
      @eventCancel="onEventCancel"
    />
  </AppContentLayout>
  <ConfirmDialog
    v-if="confirmationDialogState && confirmationDialogState.options"
    v-model:visible="confirmationDialogState.visible"
    :title="confirmationDialogState.options.title"
    :description="confirmationDialogState.options.description"
    :submitPending="submitPending"
    @confirm="onDialogConfirm"
    @reject="onDialogReject"
  />
</template>

<script lang="ts">
import AppContentLayout from '@bd/admin/components/AppContentLayout.vue'
import {
  ConfirmDialog,
  EventDetails,
  useCalendarEventActions,
  useLoadableResourceDataState,
} from '@bd/components'
import { defineComponent } from 'vue'

export default defineComponent({
  components: { AppContentLayout, EventDetails, ConfirmDialog },
  setup() {
    const { eventDetails, ...calendarEventActions } = useCalendarEventActions()
    const eventDetailsDataState = useLoadableResourceDataState(eventDetails)

    return {
      eventDetails,
      ...eventDetailsDataState,
      ...calendarEventActions,
    }
  },
})
</script>

<style lang="scss" scoped></style>
